import { render, staticRenderFns } from "./model-look.vue?vue&type=template&id=18c0536b&scoped=true&"
import script from "./model-look.vue?vue&type=script&lang=js&"
export * from "./model-look.vue?vue&type=script&lang=js&"
import style0 from "./model-look.vue?vue&type=style&index=0&id=18c0536b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18c0536b",
  null
  
)

export default component.exports