<template>
  <div class="parsing" v-if="content">
        <div class="img1">
          <img style="object-fit: cover" :src="content.productStyle.img1" width="100%" height="880px" alt="">
        </div>
        <div class="img2">
          <img style="object-fit: cover" :src="content.productStyle.img2" width="100%" height="326px" alt="">
        </div>
        <div class="img3">
          <img style="object-fit: cover" :src="content.productStyle.img3" width="100%" height="440px" alt="">
        </div>
        <div class="icon-list">
            <div class="icon-item"
                 v-for="(item,index) in content.productStyle.icons"
                 :key="index"
            >
                <div><img :src="item.icon" alt="" width="64px" height="64px"></div>
                <div class="title">{{item.title}}</div>
            </div>
        </div>
        <div class="info">
              <div class="title">{{content.productStyle.content1.title}}</div>
              <div class="text" style="text-align: center">{{content.productStyle.content1.description}}</div>
              <div class="url-box">
                <img :src="content.productStyle.content1.resourceUrl" width="1200px" height="600px" alt="">
              </div>

        </div>
        <div class="info">
      <div class="title">{{content.productStyle.content2.title}}</div>
      <div class="text" style="text-align: center">{{content.productStyle.content2.description}}</div>
      <div class="url-box">
        <div class="url-box" v-if="content.productStyle.content2.resourceUrl">
          <video autoplay="autoplay" loop="loop" muted width="1200px" height="600px">
            <source :src="content.productStyle.content2.resourceUrl" type="video/mp4">
          </video>
        </div>
        </div>

    </div>
        <div class="info">
      <div class="title">{{content.productStyle.content3.title}}</div>
      <div class="text">{{content.productStyle.content3.description}}</div>
      <div class="url-box">
        <img :src="content.productStyle.content3.resourceUrl" width="1200px" height="510px" alt="">
      </div>

    </div>
        <div class="parameter">
            <div class="title">{{$route.query.id=='14'?'背包设计够便捷':'硬件参数'}}</div>
            <div class="url-box">
              <img :src="content.productStyle.img4" width="1200px" height="510px" alt="">
            </div>
        </div>
<!--        <look-demo></look-demo>-->
  </div>
</template>

<script>
import lookDemo from './model-look'
export default {
  name: "parsing",
  components:{lookDemo},
  data(){
    return {
      content:null
    }
  },
  watch:{
    '$route.query.id'(){
      this.getDetail();
    }
  },
  created() {
    this.getDetail();
  },
  methods:{
    getDetail(){
          this.content=null;
          this.$get('/website/product/style4/'+this.$route.query.id).then((res)=>{
              this.content=res.data;
          }).catch((err)=>{

          })
    },
  }
}
</script>

<style scoped lang="scss">
.parsing{
  .img1{
    width: 100%;
    height: 880px;
  }
  .icon-list{
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .icon-item{
          width: 25%;
          text-align: center;
          margin-top: 30px;
    }
    .icon-item:nth-child(4n){

    }
  }
  .info,.parameter{
    width: 1200px;
    margin: 0 auto;
    .title{
      font-size: 34px;
      text-align: center;
      margin-top: 128px;
    }
    .text{
      margin: 0 auto;
      margin-top: 20px;
      width: 800px;

    }
    .url-box{
      margin-top: 30px;
    }
  }

}
</style>
