<template>
  <div class="model-look">
        <div class="title">3D预览</div>
        <div class="text">聚焦于对人员，车辆及行为的多纬度信息解析</div>
<!--        <div id="container"></div>-->
        <div class="product-list" v-if="productList.length">
            <div class="content">
              <div class="left">
                      <div>
                        <div class="name">{{productList[productIndex].products[listIndex].productName}}</div>
                        <div class="title">{{productList[productIndex].categoryName}}</div>
                        <div class="info">{{productList[productIndex].products[listIndex].productDesc}}</div>
                        <div style="cursor: pointer" @click="goProduct">了解详情></div>
                      </div>
              </div>
              <div id="container" ref="container"></div>
            </div>
            <div class="tools-box">
              <div class="tools-item"
                   :class="productIndex==index?'active':''"
                   v-for="(item,index) in productList"
                   :key="index"

              >
                <div
                    @click="changeProduct(index)"
                >
                  {{item.categoryName}}
                </div>
                <div class="list-box" v-show="productIndex==index">
                  <div class="item"
                       :class="listIndex==index1?'active':''"
                       v-for="(item1,index1) in productList[productIndex].products"
                       :key="index1"
                       @click="changeItem(index1)"
                  >
                    {{item1.productName}}
                  </div>
                </div>

              </div>
            </div>
        </div>

  </div>
</template>
<script>
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader, MTLLoader } from "three-obj-mtl-loader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
export default {
  name: "look-demo",
  data(){
    return {
      list:[],
      productList:[],
      productIndex:0,
      mesh: null,
      camera: null,
      scene: null,
      renderer: null,
      controls: null,
      listIndex:0,
    }
  },
  created() {
    this.getList();
    this.getProductList();
  },
  methods: {
    getProductList(){
      this.$get('/website/product/get3dPreview').then((res)=>{
        this.productList=res.data;
        this.$nextTick(()=>{
          this.init();
        })
      })
    },
    getList() {
      this.$get('/website/product/get3dPreview').then((res) => {
        this.list = res.data;
      }).catch((err) => {

      })
    },
    changeProduct(index){
      this.productIndex=index;
      this.listIndex=0;
      this.init();
    },
    changeItem(index){
      this.listIndex=index;
      this.init();
    },
    goProduct(){
      let item=this.productList[this.productIndex].products[this.listIndex];
      if(item.styleType==1){
        this.$router.push({
          name:'night',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==2){
        this.$router.push({
          name:'eyes',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==3){
        this.$router.push({
          name:'engine',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==4){
        this.$router.push({
          name:'parsing',
          query:{
            id:item.id
          }
        })
      }
      if(item.styleType==5){
        this.$router.push({
          name:'algorithm',
          query:{
            id:item.id
          }
        })
      }
    },
    init() {
      this.$refs.container.innerHTML='';
      this.createScene(); // 创建场景
      this.loadGLTF(); // 加载GLTF模型
      this.createLight(); // 创建光源
      this.createCamera(); // 创建相机
      this.createRender(); // 创建渲染器
      this.createControls(); // 创建控件对象
      this.render(); // 渲染
    },
    // 创建场景
    createScene() {
      this.scene = new THREE.Scene();
    },
    // 加载GLTF模型
    loadGLTF() {
      const THIS = this;
      const loader = new GLTFLoader();
      const dracoLoader = new DRACOLoader();
      //dracoLoader.setDecoderPath("/gltf/"); // 设置public下的解码路径，注意最后面的/
      //dracoLoader.setDecoderConfig({ type: "js" });
      //dracoLoader.preload();
      //loader.setDRACOLoader(dracoLoader);
      console.log(this.productList[this.productIndex].products[this.listIndex].model3d);
      // "/model/"+this.productList[this.productIndex].products[this.listIndex].model3d,
      loader.load(
          "/model/"+this.productList[this.productIndex].products[this.listIndex].model3d,
          (model) => {
             //model.scene.children[0].scale.set(50, 50, 50);
              this.setScaleToFitSize(model.scene);
             this.scene.add(model.scene);

          }
      );
    },
      //获取模型适合观察的缩放的比例
    getFitScaleValue(obj){
      var boxHelper = new THREE.BoxHelper(obj);
      boxHelper.geometry.computeBoundingBox();
      var box = boxHelper.geometry.boundingBox;
      console.log(box);
      var maxDiameter = Math.max((box.max.x - box.min.x), (box.max.y - box.min.y), (box.max.z - box.min.z));
      return this.camera.position.z / maxDiameter;
    },
    //设置模型到适合观察的大小
    setScaleToFitSize(obj) {
      var scaleValue = this.getFitScaleValue(obj);
      obj.scale.set(scaleValue, scaleValue, scaleValue);
      return scaleValue;
    },
    // 创建光源
    createLight() {
      // 环境光
      const ambientLight = new THREE.AmbientLight(0xffffff, 0.1); // 创建环境光
      this.scene.add(ambientLight); // 将环境光添加到场景

      const spotLight = new THREE.SpotLight(0xffffff); // 创建聚光灯
      spotLight.position.set(150, 150, 150);
      spotLight.castShadow = true;
      this.scene.add(spotLight);
    },
    // 创建相机
    createCamera() {
      const element = document.getElementById("container");
      const width = element.clientWidth; // 窗口宽度
      const height = element.clientHeight; // 窗口高度
      const k = width / height; // 窗口宽高比
      // PerspectiveCamera( fov, aspect, near, far )
      this.camera = new THREE.PerspectiveCamera(20, k, 1, 8000);
      this.camera.position.set(25, 10, 0); // 设置相机位置

      this.camera.lookAt(new THREE.Vector3(1, 1, 1)); // 设置相机方向
      this.scene.add(this.camera);
    },
    // 创建渲染器
    createRender() {
      const element = document.getElementById("container");
      this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      this.renderer.setSize(element.clientWidth, element.clientHeight); // 设置渲染区域尺寸
      this.renderer.shadowMap.enabled = true; // 显示阴影
      this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      this.renderer.setClearColor(0xffffff, 1); // 设置背景颜色
      element.appendChild(this.renderer.domElement);
    },
    render() {
      // if (this.mesh) {
      //   this.mesh.rotation.z += 0.006
      //   this.mesh.rotation.y += 0.006
      //   this.mesh.rotation.x += 0.006
      // }
      this.controls.update();
      this.renderer.render(this.scene, this.camera);
      requestAnimationFrame(this.render);
    },
    // 创建控件对象
    createControls() {
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.autoRotate = true // 是否自动旋转
    },

  },
  mounted() {

  }
}
</script>

<style scoped lang="scss">
#container{
  width: 300px;
  height: 300px;
  margin-left: 50px;
}
.model-look{
  .title{
    font-size: 34px;
    text-align: center;
    margin-top: 50px;
  }
  .text{
    margin-top: 20px;
    text-align: center;
  }
  .product-list{
    width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
    .content{
      display: flex;
      justify-content: center;
      .left{
          display: flex;
          align-items: center;
          .name{
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .title{
            font-size: 18px;
            margin-top: 0px;
            text-align: left;
            margin-bottom: 5px;

        }
          .info{
          font-size: 14px;
          margin-bottom: 5px;

        }
      }
    }
    .tools-box{
      border-top:1px solid #797979;
        display: flex;
        justify-content: center;
      .tools-item{
          padding: 5px 10px;
          cursor: pointer;
          border-top: 2px solid transparent;
          color: #797979;
          position: relative;
          .list-box{
            position: absolute;
            width: 200px;
            .item{
              color: #797979;
              padding-top: 15px;
            }
            .item.active{
              color: black;
            }
          }
      }
      .tools-item.active{
        border-color: black;
        color: black;
      }
    }


  }
}
</style>
